const INFO = {
  main: {
    title: "Tanner Helton",
    name: "Tanner Helton",
    email: "contact@tannerhelton.com",
    logo: "../logo.png",
  },

  socials: {
    twitter: "https://twitter.com/",
    github: "https://github.com/tannerhelton",
    linkedin: "https://linkedin.com/in/tannerhelton",
    instagram: "https://instagram.com/",
    stackoverflow: "https://stackoverflow.com/",
    facebook: "https://facebook.com/",
  },

  homepage: {
    title: "Entrepreneur and Full-Stack Developer",
    description:
      "I'm a fourth year student at the University of Kansas studying Computer Science and Business. I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
  },

  about: {
    title:
      "I’m Tanner Helton, an entrepreneur and software engineer based in Kansas City.",
    description:
      "I'm a fourth-year student at the University of Kansas studying Computer Science, with a certificate in Cybersecurity and minors in mathematics and business. I'm interested in startups, tech, networking, and rockets! Here's my favorite quote, credit to the Eagles: “So often times it happens that we live our lives in chains, and we never even know we have the key”",
  },

  articles: {
    title:
      "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
    description:
      "Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
  },

  projects: [
    {
      title: "Health Horizon",
      description:
        "Our application aims to provide early detection of medical diagnoses through predictive machine learning modeling. We aim to integrate an AI chatbot to talk with the patient to offer possible diagnoses based on the ML model.",
      logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
      linkText: "View Project",
      link: "https://github.com/tannerhelton/EECS581",
    },

    {
      title: "Snake - Return of the 8 bit",
      description:
        "A rendition of the classic snake game, but for iPads. The game was very simplistic and my second published iOS app. It was created entirely on the iPad itself.",
      logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/lua/lua.png",
      linkText: "App Archive",
      link: "https://appadvice.com/app/snake-return-of-the-8-bit-ipad-version/903272020",
    },
    {
      title: "Fit the Pixel",
      description:
        "Fit the Pixel was a fun iOS app that I created when I was 12 just to get familiar with programming for mobile devices. It was a simple game, but I wanted to experiment and trying to get something on the app store!",
      logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/swift/swift.png",
      linkText: "View Project",
      link: "https://github.com/tannerhelton/FitThePixel2",
    },
  ],
};

export default INFO;
